@import '../../App.scss';

.navbar {
  height: 10vh;
  width: 100%;
  background-color: white!important;
  color: #1a1a1a;

  text-transform: uppercase;
  letter-spacing: 2px;
  
  z-index: 99;
  position: sticky;
  top: 0;
  font-weight: 500;
  
  @include mobile {
    position: sticky;
    height: 8vh;
    z-index: 99;
    font-size: 18px;
    letter-spacing: 2px;
    
  }

  .wrapper{
    width: 1200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    letter-spacing: 2px;

    @include mobile{
      max-width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      // padding: 10px;
      margin: auto;
      letter-spacing: 2px;
      text-size-adjust: inherit;
    }
    
    img{
      // padding-top: 2%;
      margin: auto;
      // margin-top: 2%;
      // margin-left: 0px;
      width: 65%;
      cursor: pointer;

      @include mobile{
      padding-top: 2.5%;
      }
    }
  }

  .collapse{
    background-color: rgb(255, 255, 255, 0.9);

    a{
      color: #1a1a1a;

      &:hover{
        color: #fdc112;
      }
    }

    @include mobile{
      // margin-top: 1.75vh;
      padding: 10px 0 10px 30px;
    }

  }

  .tabs{
    margin-left: 0;
  }


    .upHome{
      position: fixed;
      display: flex;
      flex-direction: column;
      align-items: center;

      font-size: small;
      color: grey;
      opacity: 60%;
      bottom: 70px;
      right: 50px;
      cursor: pointer;
      z-index: 99;

      @include mobile{
      right: 20px;
      opacity: 60%;
      z-index: 99;
      }
    }

}
  
.menuToggle {
    display: none;
    cursor: pointer;
  
    @include mobile {
      display: flex;
      flex-direction: column;
    }
  
    .burger {
      width: 25px;
      height: 3px;
      background-color: #1a1a1a;
      margin: 6px 0;
    }
}
  
.menu {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // right: 0;
    margin: auto;
    z-index: 99;
  
    @include mobile {
      display: none;
      flex-direction: column;
      align-items: center;
      width: 75%;
      background-color: rgba(255, 255, 255, 0.92);
      position: absolute;
      top: 8vh;
    //   left: 0;
      right: 0;
      padding: 10px;
    }
  
    &.open {
      display: flex;
    }
  
    li {
      margin-right: 0;
      margin-left: 20px;
  
      @include mobile {
        margin: 10px 0;
      }
  
      a {
        text-decoration: none;
        // color: #fdc112;
        cursor: pointer;

        @include mobile {
          color: #fdc112;
        }
  
        &:hover {
          color: #fdc112;
        }
      }
    }
}