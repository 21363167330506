@import '../../App.scss';

.upofferings{
    height: auto;
    background: rgb(135, 142, 79, 0.5);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-top: 100px;
    padding-bottom: 100px;

    h1{
        text-align: center;
    }

    img{
        padding-bottom: 50px;
    }

    @include mobile{
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .mainContent{
        max-width: 1200px;
        
        @include mobile{
            width: 75%;
            flex-wrap: wrap;
        }
    }
    
}