@import '../../App.scss';

.contact{
    height: auto;
    background: rgb(135, 142, 79, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;

    @include mobile{
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .mainContent{
        width: 1200px;
        display: flex;
        justify-content: center;

        @include mobile{
            width: 100%;
        }

        .inputform{
            width: 77%; //fixes width of the three inputs
            // height: 100%;
            display: flex;
            flex-direction: column;
            // align-items: center;
            gap: 20px;
            position: relative;
        }

        input{
            width: 100%; //fixes width of the two inputs (60% of 100% of above)
            padding: 8px;
            border: 1px solid grey;
            border-radius: 5px;        
        }

        textarea{
            max-width: 100%;
            min-width: 100%;
            padding: 8px;
            border: 1px solid grey;
            border-radius: 5px;
        }

        .button{
            width: auto;
            margin: auto;

            padding: 8px 50px 8px 50px;

            color: white;
            background-color: #fdc112;
            border: none;
            font-weight: 500;
            cursor: pointer;
            text-transform: uppercase;

            &:hover{
                background-color: #f0a224;
            }
            
        }
    }
}