@import '../../App.scss';

.testimonials{
    // height: 100vh;   
    background: #f0a224;
    display: flex;
    justify-content: center;
    // gap: 35px;
    
    h1{
        color: white;
    }

    
    .mainContent{
        width: 1200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 100px;
        padding-bottom: 100px;

        @include mobile{
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }
    
}