html{
  // scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  // overflow: hidden;
}

*{
  // padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

h1, h2, h3, h4, h5, h6, p {
  // font-family: 'Poppins', sans-serif;
  font-style: normal;
  color: #1a1a1a;
}

h1{
  font-size: 2.5rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 3px;
}

h2{
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-wrap: nowrap;
}

h4{
  letter-spacing: 0.1rem;
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}

body{
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  background-color: #ffffff;
  color: #1a1a1a;
}

p{
  color: rgb(102, 102, 102);
  text-align: justify;
}

li{
  list-style: none;
}

a{
  text-decoration: none;
  color: inherit;
}

section{
  // height: 100vh;
  // background: linear-gradient(0deg, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
  // background: white;
  background: #fff2d9;
  scroll-snap-align: center;
}

button{
  // width: auto; /* '*' */
  // padding: 8px;
  border: 1px solid white;
  // border-radius: 50px;
  // background-color: transparent;
  // color: white;
  // cursor: pointer;
  // font-weight: 500;
  // font-size: 14px;
  // margin-right: 20px;

  color: white;
  background-color: #fdc112;
  border: none;
  cursor: pointer;
  font-weight: 500;
  // font-size: 14px;
  // margin-right: 20px;

  &:hover{
  background-color: #f0a224;
  }
}

@mixin mobile{
  @media (max-width: 768px) {
      @content;
  }
}

@mixin tablet{
  @media (max-width: 1024px) {
      @content;
  }
}

@mixin desktop{
  @media (max-width: 1440px) {
      @content;
  }
}