@import '../../App.scss';

.hero{
    // max-width: 1200px;
    width: 100%;
    // height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 12vh;
    // padding: 12vh;
    overflow: hidden;
    margin: auto;

    @include mobile{
        // width: 100%;
        // height: 100%;
        // margin: auto;

        img {
            // max-height: 200px; // Adjust image height for smaller screens
            width: 100%;
            // height: auto;
          }
    }
}