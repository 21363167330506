@import '../../../App.scss';

.title{
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 75px;

    h1{
        text-align: center;
    }

    .mainContent{
        width: 1200px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: auto;

        @include mobile{
            width: 100%;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: auto;
            gap: 50px;

        }

        .left{
            width: 60%;
            padding-right: 25px;

            img{
                padding-bottom: 25px;
                // padding-right: 25px;
            }

            @include mobile{
                width: 80%;
                padding-right: 0;
            }
        }

        .right{
            width: auto;
            background-color: rgb(135, 142, 79, 0.5);
            padding: 50px;

            display: flex;
            flex-direction: column;
            align-items: center;

            @include mobile{
                width: 85%;
            }

            .card{
                width: 300px;
                height: auto;
                padding: 20px;
                // background-color: #fff2d9;
                background-color: rgb(255, 255, 255, 0.8);

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                -webkit-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.2);
                -moz-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.2);
                box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.2);

                h5{
                    // margin-bottom: 0;
                    // width: 95%;
                    margin: auto;
                }

                p{
                    text-align: center;
                }

                button{
                    color: white;
                    background-color: #fdc112;
                    border: none;
                    cursor: pointer;
                    font-weight: 500;

                    &:hover{
                    background-color: #f0a224;
                    }
                }
            }
        }
    }
} 