@import '../../App.scss';

.footerDistributed{
  background: #686847;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 100%;
  text-align: left;
//   font: bold 16px sans-serif;
  padding: 30px 0px 30px 0;
  display: flex;
//   flex-direction: row;
//   align-items: center;
  justify-content: center;
}

.mainContent{
    width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // align-items: center;

    @include mobile{
      display: flex;
      flex-direction: column;
      align-items: center;
    }

}

.footerDistributed .footerLeft,
.footerDistributed .footerCenter,
.footerDistributed .footerRight{
  display: inline-block;
  vertical-align: top;
}

/* Footer left */

.footerDistributed .footerLeft{
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  // margin-top: 1%;

  @include mobile{
    width: 100%;
    justify-content: center;
    margin-bottom: 7%;
  }
}

/* The company logo */

.footerDistributed h3{
  color:  #ffffff;
//   font: normal 36px 'Open Sans', cursive;
  margin: 0;
}

.footerDistributed h3 span{
  color:  lightseagreen;
}

.footerDistributed img{
    width: 50%;
}

/* Footer links */

.footerDistributed .footerLinks{
  color:  #ffffff;
  margin: 20px 0 12px;
  padding: 0;
}

.footerDistributed .footerLinks a{
  display:inline-block;
  line-height: 1.8;
  font-weight:400;
  text-decoration: none;
  color:  inherit;
}

.footerCompanyName{
  padding: 0.5rem 0;
  text-align: center;
  font-size: 0.7rem;
  font-weight: 400;
  letter-spacing: 0.125rem;
  margin: auto;
}

.footerCompanyName a{
  letter-spacing: 0.125rem;
  font-weight: 400;
}

/* Footer Center */

.footerDistributed .footerCenter{
  width: 35%;
  align-self: center;

  a{
    &:hover{
      color: #fdc112;
    }
  }

  @include mobile{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    gap: 25px;
    margin-bottom: 4%;
  }
}

.footerDistributed .footerCenter i{
  background-color:  #33383b;
  color: #ffffff;
  font-size: 25px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
}

.footerDistributed .footerCenter i.fa-envelope{
  font-size: 17px;
  line-height: 38px;
}

.footerDistributed .footerCenter p{
  display: inline-block;
  color: #ffffff;
  font-weight:400;
  vertical-align: middle;
  margin:0;
}

.footerDistributed .footerCenter p span{
  display:block;
  font-weight: normal;
  font-size:14px;
  line-height:2;
}

.footerDistributed .footerCenter p a{
  // color:  lightseagreen;
  color: #ffffff;
  text-decoration: none;;
}

.footerDistributed .footerLinks a:before {
  content: "|";
  font-weight:300;
  font-size: 20px;
  left: 0;
  color: #ffffff;
  display: inline-block;
  padding: 0 8px 0 8px;
}

.footerDistributed .footerLinks .link1:before {
  content: none;
}

/* Footer Right */

.footerDistributed .footerRight{
  width: 25%;

  a{
    // color: #1a1a1a;

    &:hover{
      color: #fdc112;
    }
  }

  @include mobile{
    width: 80%;
    text-align: center;

    p{
      text-align: inherit;
    }
  }
}

.footerDistributed .footerCompanyAbout{
  line-height: 20px;
  color:  #ffffff;
  font-size: 13px;
  font-weight: 200;
  margin: 0;
}

// .footerDistributed .footerCompanyAbout span{
//   display: block;
//   color:  #ffffff;
//   font-size: 14px;
//   font-weight: bold;
//   text-transform: uppercase;
//   margin-bottom: 20px;
// }

.footerDistributed .footerIcons{
  margin-top: 20px;
}

.footerDistributed .footerIcons a{
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  // background-color:  blue;
  // border-radius: 2px;

  font-size: 20px;
  color: #ffffff;
  text-align: center;
  line-height: 35px;

  margin-right: 20px;
  margin-bottom: 5px;
}

/* If you don't want the footer to be responsive, remove these media queries */

@media (max-width: 880px) {

  .footer-distributed{
    font: bold 14px sans-serif;
  }

  .footer-distributed .footer-left,
  .footer-distributed .footer-center,
  .footer-distributed .footer-right{
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }

  .footer-distributed .footer-center i{
    margin-left: 0;
  }

}