@import '../../App.scss';

.offerings{
    height: 80vh;
    background: #a8a361;
    display: flex;
    justify-content: center;

    .mainContent{
        max-width: 1200px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;        
        
        @include mobile{
            display: flex;
            flex-direction: column;
        }

    }

    h2{
        color: white;
    }

    p{
        color: white;
        width: 100%;
    }

    .right{
        width: 50%;

        img{
            width: 95%;
        }

        @include mobile{
            img{
                padding-bottom: 15px;
            }
        }
    }

    .left{
        width: 60%;

        display: flex;
        flex-direction: column;
        align-items: center;

        img{
            width: 150px;
        }
    }
}