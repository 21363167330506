@import '../../App.scss';

.offerings{
    height: auto;
    // background: #a8a361;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;

    @include mobile{
        padding-top: 50px;
        padding-bottom: 50px;
    }

    img{
        padding-bottom: 50px;
    }

    .mainContent{
        max-width: 1200px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        
        img{
            padding-bottom: 0;
        }
    }

    p{
        width: 100%;
    }
}

.button{
    // width: auto; /* '*' */
    // padding: 8px;
    // border: 1px solid white;
    // border-radius: 5px;
    // background-color: transparent;
    color: white;
    background-color: #fdc112;
    border: none;
    // border-radius: 5%;
    cursor: pointer;
    font-weight: 500;
    // font-size: 14px;
    // margin-right: 20px;

    &:hover{
    background-color: #f0a224;
    }
}