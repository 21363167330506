@import '../../App.scss';

.carousel{
  width: 1200px;
  max-width: 720px;
  margin: 0 auto;
  background:rgb(255, 255, 255, 0.8);

  @include mobile{
    width: 325px;
  }
}