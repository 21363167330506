@import '../../App.scss';

.main{
  width: 100%;

  .item{
    display: flex;
    justify-content: center;

    @include mobile{
      // width: auto;
    }
  }
}

.card{
  width: 450px;
  height: auto;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);

  @include mobile{
    width: fit-content;
    height: auto;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  }
}

.cardContent{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  color: #f0a224;

  @include mobile{
    width: max-content;
  }
  
  .button{
    color: white;
    background-color: #fdc112;
    border: none;
    cursor: pointer;
    font-weight: 500;

    &:hover{
    background-color: #f0a224;
    }
  }
  
  // .carousel-svg {
    //     width: 100px;
    //     height: auto;
    //     margin-bottom: 20px;
    //     // fill: black;
    //   }
    
    .carousel-caption {
      h3 {
        font-size: 1.5rem;
        font-weight: 700;
      }
      
      p {
        font-size: 1rem;
        margin: 5px 0;
      }
    }
    
  }
  .carousel-indicators.loaded {
    display: flex;
    justify-content: center; /* Center the indicators */
    padding: 0; /* Remove any default padding */
  }
  // .carousel {
  //   display: flex;
  //   flex-direction: column-reverse;
  // }