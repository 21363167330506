@import '../../App.scss';

.about{
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;

    @include mobile{
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .mainContent{
        width: 1200px;

        @include mobile{
            width: 80%;

            h2{
                text-wrap: inherit;
                text-align: center;
            }
        }
    }

}