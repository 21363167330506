@import '../../App.scss';

.container{
    width: 55%;
    height: auto;
    align-items: center;
    display: flex;
    justify-content: center;

    @include mobile{
        width: 80%;
    }

    .form{
        text-align: left;
        background-color: rgb(135, 142, 79, 0.5);
        padding: 5%;
        border-radius: 5px;

        .button{
            width: auto;
            color: white;
            background-color: #fdc112;
            border: none;
            cursor: pointer;
            font-weight: 500;

            float: right;

            &:hover{
            background-color: #f0a224;
            }
        }
    }
}
a {
    color:blueviolet;
}